import React from "react";
import { Text, Flex, Icon, Card, CardBody } from "@chakra-ui/react";
import styles from "./CareerPage.module.css";
import Footer from "../components/Footer";
import { CiPhone, CiMail, CiLocationOn } from "react-icons/ci";

const CareerPage = () => {
  return (
    <Flex flexDirection="column" overflowX="hidden">
      <Flex className={styles.headerContainer} backgroundColor="blackAlpha.300">
        <Text className={styles.careerTitle}>Work With Us</Text>
        <Text className={styles.careerSubText}>
          Join Our Dynamic Team and Forge a Path to Professional Excellence in
          an Innovative and Supportive Environment!
        </Text>
      </Flex>
      <Text className={styles.contactTitle}>
        Contact us through any of the following methods to inquire about job
        openings
      </Text>
      <Flex className={styles.contactCards}>
        <Card className={styles.card}>
          <CardBody className={styles.cardBody}>
            <Text className={styles.cardTitle}>Message</Text>
            <Text className={styles.cardSubText}>
              Send us a call or message on WhatsApp
            </Text>
            <Flex flexDirection="column" alignItems="center">
              <Icon
                as={CiPhone}
                w={10}
                h={10}
                color="#e93d27"
                backgroundColor="blackAlpha.300"
                borderRadius="20px"
                margin="8px"
                padding="2px"
              />
              <a
                href="https://wa.me/6285117255773"
                target="_blank"
                rel="noreferrer"
              >
                <Text className={styles.contactText}>+62 851-1725-5773</Text>
              </a>
            </Flex>
          </CardBody>
        </Card>
        <Card className={styles.card}>
          <CardBody className={styles.cardBody}>
            <Text className={styles.cardTitle}>Email</Text>
            <Text className={styles.cardSubText}>
              Contact us by sending us a message via email
            </Text>
            <Flex flexDirection="column" alignItems="center">
              <Icon
                as={CiMail}
                w={10}
                h={10}
                color="#e93d27"
                backgroundColor="blackAlpha.300"
                borderRadius="20px"
                margin="8px"
                padding="2px"
              />
              <a href="mailto:info@kuriacomposit.com" target="_blank">
                <Text className={styles.contactText}>
                  info@kuriacomposit.com
                </Text>
              </a>
            </Flex>
          </CardBody>
        </Card>
        <Card className={styles.card}>
          <CardBody className={styles.cardBody}>
            <Text className={styles.cardTitle}>Visit</Text>
            <Text className={styles.cardSubText}>
              Schedule an appointment and come visit us
            </Text>
            <Flex flexDirection="column" alignItems="center">
              <Icon
                as={CiLocationOn}
                w={10}
                h={10}
                color="#e93d27"
                backgroundColor="blackAlpha.300"
                borderRadius="20px"
                margin="8px"
                padding="2px"
              />
              <a
                target="_blank"
                href="https://www.google.com/maps/place/Jl.+Singotoro+Raya+No.39,+Karanganyar+Gn.,+Kec.+Candisari,+Kota+Semarang,+Jawa+Tengah+50256/@-7.0167673,110.4317513,17z/data=!3m1!4b1!4m6!3m5!1s0x2e708d203d6c45b5:0x67a5a12483832ad3!8m2!3d-7.0167726!4d110.4343262!16s%2Fg%2F11sckdc9sc?entry=ttu"
              >
                <Text className={styles.contactText}>Singotoro Raya No 39</Text>
                <Text className={styles.contactText}>
                  Semarang, Jawa Tengah
                </Text>
                <Text className={styles.contactText}>Indonesia, 50256</Text>
              </a>
            </Flex>
          </CardBody>
        </Card>
      </Flex>

      {/* Why Work With Us Section  */}
      {/* <Flex className={styles.workwithusSection}>
        <Text className={styles.workTitle}>Why Work With Us?</Text>
      </Flex>

      <Grid className={styles.body}>
        <WhyUsItem
          icon={CiLink}
          title="Collaborative Culture"
          description="Joining our team means being part of a supportive community that
                encourages idea-sharing and creativity."
        />
        <WhyUsItem
          icon={CiMedal}
          title="Collaborative Culture"
          description="Joining our team means being part of a supportive community that
                encourages idea-sharing and creativity."
        />
        <WhyUsItem
          icon={CiBeerMugFull}
          title="Collaborative Culture"
          description="Joining our team means being part of a supportive community that
                encourages idea-sharing and creativity."
        />
        <WhyUsItem
          icon={CiDesktop}
          title="Collaborative Culture"
          description="Joining our team means being part of a supportive community that
                encourages idea-sharing and creativity."
        />
      </Grid> */}

      <Flex className={styles.imagesContainer}>
        <img className={styles.careerImg1} src="/career1.jpg" />
        <img className={styles.careerImg2} src="/career2.jpg" />
      </Flex>
      <Footer />
    </Flex>
  );
};

export default CareerPage;

const WhyUsItem = ({ icon, title, description }) => {
  return (
    <Flex>
      <Icon as={icon} w={10} h={10} marginRight="16px" color="#e93d27" />
      <Flex flexDirection="column">
        <Text fontSize="xl">{title}</Text>
        <Text fontSize="sm">{description}</Text>
      </Flex>
    </Flex>
  );
};
