import React, { useRef } from "react";
import { Flex, Text, Grid, GridItem, Button, Box } from "@chakra-ui/react";
import styles from "./HomePage.module.css";
import Footer from "../components/Footer";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { easeOut, motion, useScroll, useTransform } from "framer-motion";
import Comparison from "../components/Comparison";

const HomePage = () => {
  const targetRef = useRef(null);

  const { scrollYProgress } = useScroll({ target: targetRef });
  const x = useTransform(scrollYProgress, [0, 1], ["0%", "-120%"]);

  return (
    <Flex className={styles.container}>
      <Flex className={styles.headerSection}>
        <Flex className={styles.headerTextContainer}>
          <motion.div
            initial={{ x: "-100vw" }} /* Start off-screen to the left */
            animate={{ x: 0 }}
            transition={{ ease: easeOut, duration: 2 }}
            width="100%"
          >
            <Text className={styles.headerTitleText}>Kuria Bar</Text>
            <Text className={styles.headerSubTitle}>1/4x Bobot Besi</Text>
            <Text className={styles.headerSubTitle}>3x Lebih Kuat</Text>

            <Flex className={styles.headerDescContainer}>
              <Text className={styles.headerDescText}>
                We are stronger, lighter, and more durable than steel. Kuria bar
                is corrosion resistant and low maintenance. Try the option that
                works for your budget.
              </Text>
              <Button className={styles.consultButton} bg="white">
                <a
                  href="https://wa.me/6285117255773"
                  target="_blank"
                  rel="noreferrer"
                >
                  Consult Now
                </a>
              </Button>
            </Flex>
          </motion.div>
        </Flex>
      </Flex>
      <img className={styles.headerImg2} src="headerImg.png" />

      {/* Carousel Section */}
      <div className={styles.carouselSection} ref={targetRef}>
        <div className={styles.scrollSection}>
          <Flex flexDirection="column" alignItems="flex-start">
            <Text className={styles.carouselTitleText}>
              Revolutionizing Construction
            </Text>
            <Text className={styles.carouselSubTitleText}>with Kuria Bar</Text>
          </Flex>
          <motion.div className={styles.scrollLine} style={{ x }}>
            <Flex className={styles.contentContainer}>
              <Flex className={styles.scrollBoxContainer}>
                <ScrollBox
                  icon="Frame 467.png"
                  title="Lightweight"
                  text="Rebar komposit jauh lebih ringan daripada baja, sehingga mengurangi biaya transportasi dan penanganan."
                />
                <ScrollBox
                  icon="corrosionIcon.png"
                  title="Corrosion Resistance"
                  text="Sangat tahan terhadap korosi, membuatnya ideal untuk aplikasi di lingkungan yang keras seperti daerah pesisir atau pabrik kimia."
                />
                <ScrollBox
                  icon="electricIcon.png"
                  title="Non-Conductive"
                  text="Tidak seperti baja, rebar komposit tidak menghantarkan listrik, mencegah korosi galvanik dan meminimalkan gangguan pada sistem listrik."
                />
                <ScrollBox
                  icon="thermalIcon.png"
                  title="Thermal Insulation"
                  text="Rebar komposit memiliki sifat isolasi termal yang superior, membuatnya cocok untuk aplikasi di mana kontrol suhu sangat penting.
    "
                />
              </Flex>
              <img className={styles.kuriaBar} src="kuria_bar.png" />
              <Flex paddingLeft="10vw" className={styles.scrollBoxContainer}>
                <ScrollBox
                  icon="fatigueIcon.png"
                  title="Fatigue Resistance"
                  text="Menunjukkan ketahanan kelelahan yang sangat baik, memastikan daya tahan jangka panjang dalam aplikasi yang mengalami pembebanan siklik."
                />
                <ScrollBox
                  icon="chainIcon.png"
                  title="Enhanced Bond Strength"
                  text="Rebar komposit seringkali dapat mencapai kekuatan ikatan yang lebih baik dengan beton daripada baja, sehingga meningkatkan kinerja struktural."
                />
                <ScrollBox
                  icon="leafIcon.png"
                  title="Environmentally Friendly"
                  text="Dengan konsumsi energi yang lebih rendah dalam proses produksinya, tulangan komposit menjadi pilihan yang lebih berkelanjutan sebagai pengganti tulangan baja"
                />
                <ScrollBox
                  icon="savingIcon.png"
                  title="Potential Cost Savings"
                  text="Rebar komposit dapat menawarkan penghematan biaya dalam jangka panjang karena daya tahannya dan kebutuhan pemeliharaan yang berkurang."
                />
              </Flex>
            </Flex>
          </motion.div>
        </div>
      </div>

      {/* Comparison Section  */}
      <Comparison />

      {/* Application Section  */}
      <Flex className={styles.applicationSection}>
        <Text className={styles.applicationHeader}>Successful Projects</Text>
        <ProjectsCarouselMobile />
        <ProjectsDesktop />
      </Flex>

      {/* Try Kuria Now Banner  */}
      <Flex className={styles.callOutBanner}>
        <Text className={styles.callOutText}>Have a project in mind?</Text>
        <CallOutButton />
      </Flex>

      <Footer />
    </Flex>
  );
};

export default HomePage;

function ScrollBox({ icon, title, text }) {
  return (
    <Flex className={styles.scrollBox}>
      <img className={styles.scrollIcon} src={icon} />
      <Text className={styles.scrollTitle}>{title}</Text>
      <Text className={styles.scrollText}>{text}</Text>
    </Flex>
  );
}

function CallOutButton() {
  return (
    <Box
      as="button"
      className={styles.callOutButton}
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      _hover={{ bg: "#ebedf0" }}
      _active={{
        bg: "#dddfe2",
        transform: "scale(0.98)",
        borderColor: "#bec3c9",
      }}
      _focus={{
        boxShadow:
          "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
      }}
    >
      <a href="https://wa.me/6285117255773" target="_blank" rel="noreferrer">
        TRY KURIA BAR NOW
      </a>
    </Box>
  );
}

function ProjectsDesktop() {
  return (
    <Grid className={styles.applicationImgContainer}>
      <GridItem rowSpan={2} colSpan={1}>
        <Flex className={styles.applicationTextContainer}>
          <Text className={styles.applicationText}>
            Jatengland Industrial Park Sayung - Jawa Tengah
          </Text>
          <Text className={styles.applicationSubText}>
            Ruko 3 Lantai - Jalan Kawasan Industri
          </Text>
        </Flex>
        <img className={styles.applicationImg} src="appImg1.png" />
      </GridItem>

      <GridItem rowSpan={2} colSpan={1}>
        <img className={styles.applicationImg} src="appImg2.png" />
        <Flex className={styles.applicationTextContainer}>
          <Text className={styles.applicationText}>
            Kawasan Industri Candi Gatot Subroto - Jawa Tengah
          </Text>
          <Text className={styles.applicationSubText}>
            Jalan Kawasan Industri
          </Text>
        </Flex>
      </GridItem>
      <GridItem rowSpan={2} colSpan={1}>
        <Flex className={styles.applicationTextContainer}>
          <Text className={styles.applicationText} width="80%">
            Entertainment Center 4 Lantai
          </Text>
          <Text className={styles.applicationSubText}>
            Pearl of Java - Semarang
          </Text>
        </Flex>
        <img className={styles.applicationImg} src="appImg3.png" />
      </GridItem>
      <GridItem rowSpan={2} colSpan={1}>
        <img className={styles.applicationImg} src="appImg4.png" />
        <Flex className={styles.applicationTextContainer}>
          <Text className={styles.applicationText}>Platinum - Pekanbaru</Text>
          <Text className={styles.applicationSubText}>
            Proyek Development Pergudangan
          </Text>
        </Flex>
      </GridItem>
    </Grid>
  );
}

function ProjectsCarouselMobile() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: (dots) => (
      <ul style={{ margin: "0px", marginBottom: "48px" }}> {dots} </ul>
    ),
  };

  return (
    <div className={styles.sliderContainer}>
      <Slider {...settings}>
        {/* TODO: each item here is copy pasted from ProjectsDesktop for now, but we should be able to share the code between the two */}
        <div>
          <Flex className={styles.applicationTextContainer}>
            <Text className={styles.applicationText}>
              Jatengland Industrial Park Sayung - Jawa Tengah
            </Text>
            <Text className={styles.applicationSubText}>
              Ruko 3 Lantai - Jalan Kawasan Industri
            </Text>
          </Flex>
          <img className={styles.applicationImg} src="appImg1.png" />
        </div>
        <div>
          <Flex className={styles.applicationTextContainer}>
            <Text className={styles.applicationText}>
              Kawasan Industri Candi Gatot Subroto - Jawa Tengah
            </Text>
            <Text className={styles.applicationSubText}>
              Jalan Kawasan Industri
            </Text>
          </Flex>
          <img className={styles.applicationImg} src="appImg2.png" />
        </div>
        <div>
          <Flex className={styles.applicationTextContainer}>
            <Text className={styles.applicationText} width="80%">
              Entertainment Center 4 Lantai
            </Text>
            <Text className={styles.applicationSubText}>
              Pearl of Java - Semarang
            </Text>
          </Flex>
          <img className={styles.applicationImg} src="appImg3.png" />
        </div>
        <div>
          <Flex className={styles.applicationTextContainer}>
            <Text className={styles.applicationText}>Platinum - Pekanbaru</Text>
            <Text className={styles.applicationSubText}>
              Proyek Development Pergudangan
            </Text>
          </Flex>
          <img className={styles.applicationImg} src="appImg4.png" />
        </div>
      </Slider>
    </div>
  );
}
