import { React, useMemo, useState } from "react";
import { Flex, Text, Card, Icon, CardBody } from "@chakra-ui/react";
import Footer from "../components/Footer";
import styles from "./ContactPage.module.css";
import {
  CiPhone,
  CiMail,
  CiLocationOn,
  CiCircleQuestion,
} from "react-icons/ci";

const ContactPage = () => {
  return (
    <Flex flexDirection="column" overflow="hidden">
      <Flex className={styles.headerContainer} backgroundColor="blackAlpha.300">
        <Text className={styles.headerText}>Contact Us</Text>
        <Text className={styles.headerSubText}>
          Ways to reach us for further inquiries or assistance
        </Text>
      </Flex>

      <Flex className={styles.contactCards}>
        <Card className={styles.card}>
          <CardBody className={styles.cardBody}>
            <Text className={styles.cardTitle}>Message</Text>
            <Text className={styles.cardSubText}>
              Send us a call or message on WhatsApp
            </Text>
            <Flex flexDirection="column" alignItems="center">
              <Icon
                as={CiPhone}
                w={10}
                h={10}
                color="#e93d27"
                backgroundColor="blackAlpha.300"
                borderRadius="20px"
                margin="8px"
                padding="2px"
              />
              <a
                href="https://wa.me/6285117255773"
                target="_blank"
                rel="noreferrer"
              >
                <Text className={styles.contactText}>+62 851-1725-5773</Text>
              </a>
            </Flex>
          </CardBody>
        </Card>
        <Card className={styles.card}>
          <CardBody className={styles.cardBody}>
            <Text className={styles.cardTitle}>Email</Text>
            <Text className={styles.cardSubText}>
              Contact us by sending us a message via email
            </Text>
            <Flex flexDirection="column" alignItems="center">
              <Icon
                as={CiMail}
                w={10}
                h={10}
                color="#e93d27"
                backgroundColor="blackAlpha.300"
                borderRadius="20px"
                margin="8px"
                padding="2px"
              />
              <a href="mailto:info@kuriacomposit.com" target="_blank">
                <Text className={styles.contactText}>
                  info@kuriacomposit.com
                </Text>
              </a>
            </Flex>
          </CardBody>
        </Card>
        <Card className={styles.card}>
          <CardBody className={styles.cardBody}>
            <Text className={styles.cardTitle}>Visit</Text>
            <Text className={styles.cardSubText}>
              Schedule an appointment and come visit us
            </Text>
            <Flex flexDirection="column" alignItems="center">
              <Icon
                as={CiLocationOn}
                w={10}
                h={10}
                color="#e93d27"
                backgroundColor="blackAlpha.300"
                borderRadius="20px"
                margin="8px"
                padding="2px"
              />
              <a
                target="_blank"
                href="https://www.google.com/maps/place/Menara+Suara+Merdeka/@-6.986016,110.4105773,975m/data=!3m2!1e3!4b1!4m16!1m9!4m8!1m0!1m6!1m2!1s0x2e708b4558eeba51:0xb7aa9eb9e8fe737b!2sJl.+Pandanaran+No.30,+Pekunden,+Kec.+Semarang+Tengah,+Kota+Semarang,+Jawa+Tengah+50134!2m2!1d110.4131522!2d-6.9860213!3m5!1s0x2e708b4558eeba51:0xb7aa9eb9e8fe737b!8m2!3d-6.9860213!4d110.4131522!16s%2Fg%2F1tdz27lf?entry=ttu&g_ep=EgoyMDI0MTExMi4wIKXMDSoASAFQAw%3D%3D"
              >
                <Text className={styles.contactText}>
                  Suara Merdeka Building 8th Floor
                </Text>
                <Text className={styles.contactText}>
                  Jl. Pandanaran 30, Semarang
                </Text>
                <Text className={styles.contactText}>
                  Central Java, Indonesia
                </Text>
              </a>
            </Flex>
          </CardBody>
        </Card>
      </Flex>

      <Flex>
        <Map />
      </Flex>

      {/* FAQ Section  */}
      {/* <Flex className={styles.faqSection}>
        <Icon as={CiCircleQuestion} w={12} h={12} />
        <Text className={styles.questionText}>Questions About Us?</Text>
        <Text className={styles.questionSubText} color="gray.400">
          Learn more about our company and products on our{" "}
          <a style={{ fontWeight: "bold", textDecoration: "underline" }}>
            frequently asked questions
          </a>{" "}
          page.
        </Text>
      </Flex> */}
      <Footer />
    </Flex>
  );
};

export default ContactPage;

const Map = () => {
  const center = useMemo(() => ({ lat: 44, lng: -80 }), []);
  return (
    <iframe
      width="100%"
      height="450"
      style={{ border: 0 }}
      loading="lazy"
      allowFullScreen
      referrerPolicy="no-referrer-when-downgrade"
      // TODO: change to env variable
      src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBKyt1WNBmKnV8sqz0XhqGz6Xrxlvn0ZZo&q=Jl.+Pandanaran+No.30,+Pekunden,+Kec.+Semarang+Tengah,+Kota+Semarang,+Jawa+Tengah+50134"
    ></iframe>
  );
};
